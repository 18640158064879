

@font-face {
    font-family: 'Adina';
    src: local('Adina'), url(fonts/Adina.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
    }

.alternateFont {
    font-feature-settings: "aalt";
}

.manifestHeader {
    color: #0F0B3B;
    font-size:8vmin;
    text-align: center;
}

.testLootHeader {
    font-family:'Courier New', Courier, monospace;
    font-weight: bold;
    color: white;
    text-align: center;
}